.searchBar__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
}

.searchBar__container form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 20px;
}

.searchBar__input {
    width: 100%;
    height: 100%;
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.searchBar__container form input {
    width: 490px;
    height: 100%;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 0.5rem 1rem;
    margin: 2rem;
    outline: none;
}

.searchBar__container form input:focus {
    border: 1px solid #999;
}

.searchBar__container form button {
    width: 20%;
    height: 100%;
    border: none;
    outline: none;
    background-color: #80acff;
    border-radius: 10px;
    font-size: 1.2rem;
    color: #fff;

    cursor: pointer;
    padding: 0.5rem 1rem;
    margin: 1rem 1rem;
}

.searchBar__container form button:hover {
    background-color: #4d8aff;
}

.searchBar__container form button:active {
    background-color: #1a66ff;
}

.searchBar__container form button:focus {
    background-color: #1a66ff;
}

.searchBar__loading {
    pointer-events: none;
}

.connectWallet__component {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .searchBar__container form input {
        width: 100%;
    }

    .searchBar__container form button {
        width: 50%;
        margin: 1rem 0;
    }

    .searchBar__container form {
        flex-direction: column;
    }
}