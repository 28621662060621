.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.dashboard__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.dashboard__container__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    box-shadow: inset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    padding: 10px 0px;

    white-space: pre;
}

.dashboard__container__header__title {
    font-size: 30px;
    font-weight: 600;
    color: #80acff;
}

.dashboard__container__header h2 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.dashboard__container__header__refresh {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    margin-top: 10px;
    background-color: #80acff;
    border-radius: 20px;
    padding: 5px;
    color: #fff;

    cursor: pointer;
}

.dashboard__container__header__refresh:hover {
    background-color: #80acff;
    opacity: 0.8;
}

.dashboard__container__header__refreshing {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    margin-top: 10px;
    background-color: #80acff;
    border-radius: 20px;
    padding: 5px;
    color: #fff;

    pointer-events: none;

    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dashboard__container__header__refresing:hover {
    background-color: #80acff;
    opacity: 0.8;
}

.dashboard__container__data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 2rem;
}

.dashboard__container__data #totalProfitOPT,
#totalProfitPercent,
#totalProfitUSD {
    background-color: #31B04A;
}

#totalLossOPT,
#totalLossPercent,
#totalLossUSD {
    background-color: #F1592A;
    color: white
}

.dashboard__container__data #avgPrice {
    background-color: #FFC744;
}

.dashboard__container__data #investedOPT,
#investedUSD {
    background-color: #E1E0C7;
}

.dashboard__container__data p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    box-shadow: inset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    padding: 10px;

    border-radius: 10px;

    margin: 10px;
}

.dashboard__table__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.dashboard__table {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
}

.dashboard__table table {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    border-collapse: collapse;
    width: 100%;

    box-shadow: inset;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid black;

    padding: 10px;

    border-radius: 10px;
    text-align: center;
    vertical-align: middle;

    margin: 1rem 0rem;
}

caption {
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    color: #333;
    margin: 1rem;
  }

th,
td {
    border: 1px solid black;
    padding: 8px;
}

.dashboard__table table thead th {
    width: 25%;
}

thead {
    background-color: #333;
    color: white;
    font-size: 0.875rem;
    text-transform: uppercase;
    letter-spacing: 2%;
}

tbody tr:nth-child(odd) {
    background-color: #fff;
}

tbody tr:nth-child(even) {
    background-color: #eee;
}

.dashboard__note p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    padding: 10px;
    margin: 10px;

    font-size: small;
    color: red;
}

@media screen and (max-width: 768px) {
    .dashboard__container__data {
        flex-direction: column;
    }

    .dashboard__table__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .dashboard__container__data #totalProfitOPT,
    #totalProfitPercent,
    #totalProfitUSD {
        width:75%;
    }
    
    #totalLossOPT,
    #totalLossPercent,
    #totalLossUSD {
        width:75%;
    }
    
    .dashboard__container__data #avgPrice {
        width:75%;
    }
    
    .dashboard__container__data #investedOPT,
    #investedUSD {
        width:75%;
    }
}